import * as bootstrap from 'bootstrap';

import smoothscroll from 'smoothscroll-polyfill';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
smoothscroll.polyfill();

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

import SlickCarousel from 'slick-carousel';




// =============================
//  Google Map
// =============================
let map;

function initMap() {

    const aie_coordinates = { lat: 48.15281111451809, lng: 17.16281058829667 } ;

    const map = new google.maps.Map(document.getElementById("aie-map"), {
        center: aie_coordinates,
        zoom: 15,
        mapId: 'a77111b0e1c6c26'
    });

    const marker = new google.maps.Marker({
        position: aie_coordinates,
        map: map,
        icon: 'themes/aienergy/assets/images/map-marker.svg',
        // label: { 
        //     fontWeight: 'bold', 
        //     fontSize: '18px', 
        //     className: 'aie-marker-label',
        //     text: 'Ai-energy' 
        // },
    });
}

window.initMap = initMap;




/*
 * Application
 */
$(function() {



    window.successModal = new bootstrap.Modal(document.getElementById('successModal'), {});
    window.contactSuccessModal = new bootstrap.Modal(document.getElementById('contactFormSuccessModal'), {});
    window.popupSuccessModal = new bootstrap.Modal(document.getElementById('popupFormSuccessModal'), {});


    /*
     * Auto hide navbar
     */
    var $header = $('.navbar-autohide'),
        scrolling = false,
        previousTop = 0,
        scrollDelta = 10,
        scrollOffset = 150;

    $(window).on('scroll', function(){
        if (!scrolling) {
            scrolling = true;

            if (!window.requestAnimationFrame) {
                setTimeout(autoHideHeader, 250);
            }
            else {
                requestAnimationFrame(autoHideHeader);
            }
        }
    });

    function autoHideHeader() {
        var currentTop = $(window).scrollTop();

        // Scrolling up
        if (previousTop - currentTop > scrollDelta) {
            $header.removeClass('is-hidden');
        }
        else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
            // Scrolling down
            $header.addClass('is-hidden');
        }

        previousTop = currentTop;
        scrolling = false;
    }



    // =============================
    //  Burger icon - bg
    // =============================

    var toggleMenuBurgerBg = function() {
        if ($(window).scrollTop() >= 500) {
            $('.menu-burger').addClass('with-bg');
        } else {
            $('.menu-burger').removeClass('with-bg');
        }
    };

    toggleMenuBurgerBg();

    $(window).scroll(function(){
        if ($('.menu-burger').hasClass('active') == false) {
            toggleMenuBurgerBg();
        }
    });


    // ============================= 
    //  Offcanvas menu -> appear
    // ============================= 

    if ($('#offcanvasNavigation').length) {
       const navOffcanvas = new bootstrap.Offcanvas('#offcanvasNavigation');
        const navOffcanvasNode = $('#offcanvasNavigation');
        const menuBurger = $('.menu-burger');
        const menuBurgerInput = $('.menu-burger input');

        menuBurgerInput.click(function(event){
            navOffcanvas.toggle();
        });
        
        var timeout = null;

        navOffcanvasNode.on('show.bs.offcanvas', function(){
            menuBurgerInput.prop('checked', true);

            menuBurger.addClass('active');
            menuBurger.removeClass('with-bg');
            clearTimeout(timeout);
        });

        navOffcanvasNode.on('hide.bs.offcanvas', function(){
            menuBurgerInput.prop('checked', false);

            toggleMenuBurgerBg();
            timeout = setTimeout(function() {
                menuBurger.removeClass('active');
            }, 350 );
        });


        $('.offcanvas-anchor-link').click(function(event) {
            event.preventDefault();

            var linkHref = $(this).attr('href');

            if (linkHref == "#") {
                window.scrollTo({top: 0, left: 0});
            }
            else {
                var pos = $(linkHref).position();

                if ($(this).data('scroll-offset')){
                    pos.top = pos.top - $(this).data('scroll-offset');
                }

                window.scrollTo(pos);
            }

            navOffcanvas.hide();
        });
 
    }
    






    // ============================= 
    //  Offcanvas ponuka
    // ============================= 

    const ponukaOffcanvasNode = $('#offcanvasPonuka');

    ponukaOffcanvasNode.on('show.bs.offcanvas', function(){
        $('.btn-aie--vertical').addClass('active');
    });

    ponukaOffcanvasNode.on('hide.bs.offcanvas', function(){
        $('.btn-aie--vertical').removeClass('active');
    });

    $(window).scroll(function(){
        if ($(window).scrollTop() > 800) {
            $('#offcanvas-ponuka-btn').addClass('visible');
        } else {
            $('#offcanvas-ponuka-btn').removeClass('visible');
        }
    });





    // ============================= 
    //  Offcanvas ponuka
    // ============================= 

    //VALIDÁCIA FORMULÁRU - frontend
    $(window).on('ajaxInvalidField', function(event, fieldElement, fieldName, errorMsg, isFirst) {
        $(fieldElement).closest('.form-control').addClass('is-invalid');
        $(fieldElement).closest('.form-select').addClass('is-invalid');
        $(fieldElement).closest('.form-check-input').addClass('is-invalid');
    });

    $(document).on('ajaxPromise', '[data-request]', function() {
        $(this).closest('form').find('.form-control.is-invalid').removeClass('is-invalid');
        $(this).closest('form').find('.form-select.is-invalid').removeClass('is-invalid');
        $(this).closest('form').find('.form-check-input.is-invalid').removeClass('is-invalid');
    });






    // =============================
    //  SuccessFunc-s
    // =============================

    // POR Body Form
    window.porOffcanvasSuccessFunc = function() {
        var porOffcanvasForm = $('.por-offcanvas-form')[0];

        if (typeof dataLayer !== 'undefined') {
            var yearly_consumption = $(porOffcanvasForm).find('#yearly_consumption').val();
            var region = $(porOffcanvasForm).find('#region').val();

            dataLayer.push({
                'event' : 'Ai-energy form - bocny',
                'yearly_consumption' : yearly_consumption,
                'region': region
            });
        }

        porOffcanvasForm.reset();

        window.successModal.show();
    };

    
    // POR Body Form
    window.porBodySuccessFunc = function() {
        var porBodyForm = $('.por-body-form')[0];
        
        if (typeof dataLayer !== 'undefined') {
            var yearly_consumption = $(porBodyForm).find('#yearly_consumption').val();
            var region = $(porBodyForm).find('#region').val();

            dataLayer.push({
                'event' : 'Ai-energy form - spodny',
                'yearly_consumption' : yearly_consumption,
                'region': region
            });
        }

        porBodyForm.reset();

        window.successModal.show();
    };

    // Contact Form
    window.contactFormSuccessFunc = function() {

        var contactForm = $('.contact-form')[0];

        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                'event' : 'Ai-energy contact form'
            });   
        }

        contactForm.reset();
        window.contactSuccessModal.show();
    };

    // PopUp Form
    window.popupFormSuccessFunc = function() {
        
        var popupForm = $('.popup-form')[0];

        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                'event' : 'Ai-energy popup form'
            });   
        }

        popupForm.reset();
        window.popupModal.hide();
        window.popupSuccessModal.show();
    };







    // =============================
    //  PhotoSwipe
    // =============================


    const lightbox = new PhotoSwipeLightbox({
      gallery: '#realizacie--gallery',
      children: 'a',
      pswpModule: PhotoSwipe
    });

    const caption__lightbox = new PhotoSwipeDynamicCaption(lightbox, {
        // Plugins options, for example:
        type: 'auto',
        captionContent: '.pswp-caption-content'
    });

    lightbox.init();


    const lightbox2 = new PhotoSwipeLightbox({
      gallery: '#my-gallery',
      children: 'a',
      pswpModule: PhotoSwipe
    });

    lightbox2.init();




    const lightbox3 = new PhotoSwipeLightbox({
      gallery: '#certifikaty--gallery',
      children: 'a',
      pswpModule: PhotoSwipe
    });

    lightbox3.init();
    



    // =============================
    //  PopUp Modal
    // =============================
    // window.popupModal = new bootstrap.Modal(document.getElementById('popupModal'), {});

    // var count = sessionStorage.getItem('popupModalCount');

    // if (count == null || isNaN(count)) {
    //     count = 0;
    // } else {
    //     count++;
    // }

    // sessionStorage.setItem('popupModalCount', count);

    // if ((count % 5) == 0){    
    //     setTimeout(function(){
    //         popupModal.show();
    //     }, 5000)
    // }

});
